import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, compact } from 'lodash';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';
import { connect } from 'react-redux';

import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  buildTrimSummariesPath,
  InventoryCoreConfigurationsModel,
} from 'client/data/models/inventory-core-configurations';
import { getParamsFromVehicle, CorePageParams } from 'site-modules/shared/utils/core-page/params';
import { VisitorEntities } from 'client/data/models/visitor';
import { VehicleEntities } from 'client/data/models/vehicle-v2';
import {
  ConsumerReviewsEntities,
  ConsumerReviewsModel,
  ConsumerReviewsPaths,
} from 'client/data/models/consumer-reviews';
import { getUnifiedLeadFormDealerCountV2Path, UnifiedLeadFormModel } from 'client/data/models/unified-lead-form';
import { EditorialReviewEntities, FirstContentEntity } from 'client/data/models/editorial-review';
import { generateNewFlatUrl, srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { IncentivesAd } from 'site-modules/shared/components/native-ad/incentives-ad/incentives-ad';
import { buildDisplayVehicle } from 'site-modules/shared/utils/core-page/display-name-builders';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { ScorecardEditorialReviewBody } from 'site-modules/shared/components/core-page/scorecard-editorial-review/scorecard-editorial-review-body';
import { StickyToBottom } from 'site-modules/shared/components/sticky-to-bottom/sticky-to-bottom';
import { PhotoGallery } from 'site-modules/core-page/components/photo-gallery/photo-gallery';
import { PreprodPricingButton } from 'site-modules/core-page/components/preprod-pricing-button/preprod-pricing-button';
import {
  EditorialHighlightsLists,
  HIGHLIGHTS_NAME,
} from 'client/site-modules/shared/components/editorial-highlights-lists/editorial-highlights-lists';
import { GLOBAL_HEADER_OFFSET } from 'site-modules/shared/constants/sub-navigation';
import { SHORT_VIDEOS_MAP } from 'site-modules/core-page/constants/short-videos';
import { LOOKING_FOR_MODEL_MAP } from 'site-modules/core-page/constants/looking-for-model';
import { CORE_SRP_URL_PATTERN } from 'site-modules/shared/constants/inventory/srp-url-patterns';
import { CORE_5684_MAP } from 'site-modules/core-page/constants/core-5684';
import { OtherYears } from 'site-modules/core-page/components/other-years/other-years';
import { BuildPriceButtonAtAGlanceAd } from 'site-modules/shared/components/native-ad/build-price-button-ad/build-price-button-at-a-glance-ad';
import { YearSubmodelControls } from 'site-modules/core-page/components/year-submodel-controls/year-submodel-controls';
import { CORE_GALLERY_SIZE, MENU_HASH } from 'site-modules/core-page/utils/constants';
import { PricingModule } from 'site-modules/core-page/components/pricing-module/pricing-module';
import { AnchorNavSticky } from 'site-modules/shared/components/anchor-nav-sticky/anchor-nav-sticky';
import { ShoppingLink } from 'site-modules/shared/components/shopping-link/shopping-link';
import { ZipWithDefaultActionHandling } from 'site-modules/shared/components/zip-with-default-action-handling/zip-with-default-action-handling';
import { RangeAndCost } from 'site-modules/core-page/components/range-and-cost/range-and-cost';
import { VideoReviews } from 'site-modules/core-page/components/video-reviews/video-reviews';
import { Experiment, Recipe } from 'site-modules/shared/components/experiment';
import { NativeAdsCreativeConfigModel } from 'client/data/models/native-ads-creative-config';
import { MrectNative } from 'site-modules/shared/components/native-ad/mrect/mrect-native';
import { RatingScorecardEmbed } from 'site-modules/shared/components/core-page/scorecard-editorial-review/rating-scorecard/rating-scorecard-embed';
import { EvInsights } from 'site-modules/core-page/components/ev-insights/ev-insights';
import { LookingForModel } from 'site-modules/core-page/components/new-core-intro/looking-for-model/looking-for-model';
import { CoreHighlights, CoreHighlightsUI } from 'site-modules/core-page/components/core-highlights/core-highlights';
import { TrimsCta } from 'site-modules/shared/components/core-page/trims/trims-cta';
import { MinHeightWrapper } from 'site-modules/shared/components/min-height-wrapper/min-height-wrapper';
import { SponsoredCars } from 'site-modules/shared/components/core-page/sponsored-cars/sponsored-cars';
import { PricingAd } from 'site-modules/shared/components/native-ad/pricing-ad/pricing-ad';
import { ExperimentUtil } from 'client/utils/experiment/experiment-util';

import { VehicleInfo } from './vehicle-info';

import './new-core-intro.scss';

export function NewCoreIntroUI({
  className,
  params,
  vehicle,
  editorialReview,
  consumerRatingsCount,
  prevYearConsumerRatingCount,
  firstContent,
  hasPreprodDealers,
  isPreprod,
  visitorLocation,
  isMobile,
  srpParams,
  srpLink,
  inventoryTotalNumber,
  hasLeadFormDealers,
  isInternational,
  isConnect,
  isUnsoldBuildAndPriceAd,
  isCore5862Enabled,
  isCore6112Enabled,
  isAds9973Enabled,
  isCore6334Enabled,
}) {
  const { urlYear, noEditorialReview, isDealerlessOem, isElectric, isPluginHybrid } = params;
  const {
    year,
    make: { slug: makeSlug, name: makeName },
    model: { slug: modelSlug, name: modelName },
    submodels: { slug: submodelSlug },
  } = vehicle;

  const vehicleName = buildDisplayVehicle({
    makeModelSubmodelYear: vehicle,
    params,
    trimDuplicateSubmodel: true,
    updateChevroletMakeName: true,
    disableTrademarkCharacter: true,
  });

  const vehicleParams = getParamsFromVehicle(vehicle);
  const photoSize = isMobile ? CORE_GALLERY_SIZE.MOBILE.HERO : CORE_GALLERY_SIZE.DESKTOP.HERO;

  const hasCoreHighlights = !!(
    get(editorialReview, 'ratings.overall.rating') ||
    get(consumerRatingsCount, 'ratingAggregation.averageStars') ||
    get(prevYearConsumerRatingCount, 'ratingAggregation.averageStars')
  );
  const hasHighlights = get(editorialReview, 'cons.length') || get(editorialReview, 'pros.length');
  const hasPreprodCta = !!(hasPreprodDealers || srpLink);

  const reviewHash = noEditorialReview ? MENU_HASH.CONSUMER_REVIEWS : MENU_HASH.REVIEWS;
  let menuItems = [
    {
      hash: isMobile ? MENU_HASH.TRIM_SUMMARY_MOBILE : MENU_HASH.TRIM_SUMMARY_DESKTOP,
      name: 'Pricing',
      customOffset: isMobile ? undefined : GLOBAL_HEADER_OFFSET,
    },
    { hash: reviewHash, name: 'Review' },
  ];

  if (!isConnect) {
    menuItems = compact([
      ...menuItems,
      { hash: MENU_HASH.COMPETITION, name: 'Compare' },
      { hash: MENU_HASH.FEATURES, name: 'Specs' },
    ]);
  }

  const shortVideos = get(SHORT_VIDEOS_MAP, [makeSlug, modelSlug, year]);
  const lookingForModel = get(LOOKING_FOR_MODEL_MAP, [makeSlug, modelSlug, year]);

  const showLeadForm = hasLeadFormDealers && !isDealerlessOem;
  const hasSecondSrpLink =
    !isPreprod &&
    ((showLeadForm && !!inventoryTotalNumber) || (!showLeadForm && !inventoryTotalNumber && !isDealerlessOem));
  const hasUsedSecondSrpLink =
    !isPreprod && !hasSecondSrpLink && ((showLeadForm && !inventoryTotalNumber) || isDealerlessOem);
  const isReviewLink = !!get(editorialReview, 'reviewLink');
  const hasScorecardReview = isReviewLink && !!get(editorialReview, 'ratings.overall.rating');

  const pricingModule = !isPreprod && (
    <ScrollElement id="subnav-pricing" className="subnav-pricing px-md-1 py-2 px-0_5 bg-cool-gray-100 mx-md-0">
      <PricingModule params={params} vehicle={vehicle} srpParams={srpParams} isInternational={isInternational} />
    </ScrollElement>
  );

  const rangeAndCostModule = !isPreprod && (
    <Fragment>
      {isElectric || isPluginHybrid ? (
        <RatingScorecardEmbed childrenClassName="mb-1_5" isEmbed={hasScorecardReview}>
          <EvInsights vehicle={vehicle} isMobile={isMobile} isPluginHybrid={isPluginHybrid} />
        </RatingScorecardEmbed>
      ) : (
        <RangeAndCost params={params} vehicle={vehicle} className="mb-1_5" isMobile={isMobile} />
      )}
    </Fragment>
  );

  const filmstripModule = (
    <MinHeightWrapper componentType="SPONSORED_CARS" className="core-also-viewed-vehicles">
      <SponsoredCars trackLoading wrapperClassName="mt-2_5" showBottomDividingLine={false} showLargeArrows />
    </MinHeightWrapper>
  );

  const incentiveAdDesktop = (
    <Experiment name="ads-7490" showDefault>
      <Recipe name="ctrl" isDefault>
        <IncentivesAd
          wrapperClass={classnames({ 'mb-md-2': !isPreprod, 'mt-md-2': isPreprod })}
          horizontalLayout
          showOnlySiteServed
        />
      </Recipe>
      <Recipe name="chal">
        <IncentivesAd
          wrapperClass={classnames({ 'mb-md-2': !isPreprod, 'mt-md-2': isPreprod })}
          horizontalLayout
          isBlueBorderColor
          showOnlySiteServed
        />
      </Recipe>
    </Experiment>
  );
  const zipWithDefaultActionHandling = (
    <ZipWithDefaultActionHandling
      buttonClassName="text-primary-darker font-weight-normal text-underline py-0_25"
      showDropdownIcon={false}
    />
  );
  const noForSaleText = (
    <Fragment>
      No {modelName} vehicles for sale in our network near {zipWithDefaultActionHandling}
    </Fragment>
  );
  const noNewForSaleText = (
    <Fragment>
      No {year} {modelName} vehicles for sale in our network near {zipWithDefaultActionHandling}
    </Fragment>
  );
  const srpLinkDescription = showLeadForm ? 'Shop vehicle inventory at dealers near you' : noForSaleText;

  const secondSrpLinkProps = {
    label: 'See All for Sale',
    creativeId: 'srp-link-under-site-incentives',
    className: 'px-1 px-md-1_5 py-0_75 py-md-1 bg-white rounded-8',
    iconClassName: 'icon-price-tags2',
    iconContainerClassName: 'mr-0_75 mr-md-1_5 size-16 text-blue-50',
    iconStyles: isMobile ? { height: '40px', width: '40px' } : { height: '48px', width: '48px' },
  };

  const coreHighlights = hasCoreHighlights && (
    <Fragment>
      {isPreprod ? (
        <CoreHighlightsUI
          className="px-1 pt-1 mt-1 mt-md-1_5"
          params={params}
          submodelSlug={submodelSlug}
          vehicle={vehicle}
          editorialReview={editorialReview}
          isMobile={isMobile}
          isCore5862Enabled={isCore5862Enabled}
          isPreprod
        />
      ) : (
        <CoreHighlights
          className="px-1 pt-1 mt-1 mt-md-1_5"
          params={params}
          submodelSlug={submodelSlug}
          vehicle={vehicle}
          editorialReview={editorialReview}
          isMobile={isMobile}
          isCore5862Enabled={isCore5862Enabled}
          isCore6311Test
        />
      )}
    </Fragment>
  );

  const highlightsListFallback = (
    <EditorialHighlightsLists
      editorialReview={editorialReview}
      firstContent={firstContent}
      params={params}
      className={classnames('mb-2', { 'mb-md-0': !isPreprod })}
      isPreprod={isPreprod}
      sectionsToRender={hasHighlights ? [HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS] : [HIGHLIGHTS_NAME.WHAT_TO_EXPECT]}
    />
  );

  const introOtherYearsPills = (
    <Fragment>
      {hasSecondSrpLink && (
        <Fragment>
          <ShoppingLink
            to={generateNewFlatUrl(params)}
            data-tracking-id="floating_srp_link"
            description={isMobile ? null : srpLinkDescription}
            descriptionClassName={classnames('mt-0_25', {
              'medium font-italic': !showLeadForm,
            })}
            {...secondSrpLinkProps}
          />
          {isMobile && !showLeadForm && <div className="medium font-italic mt-0_75">{noForSaleText}</div>}
        </Fragment>
      )}
      {hasUsedSecondSrpLink && (
        <Fragment>
          <ShoppingLink
            to={srpLinkBuilder({
              make: makeSlug,
              model: modelSlug,
              year,
              inventorytype: 'new,used',
              initialUrlPattern: CORE_SRP_URL_PATTERN,
            })}
            rel="nofollow"
            data-tracking-id="floating_used_srp_link"
            description={isMobile ? null : noNewForSaleText}
            descriptionClassName="mt-0_25 medium font-italic"
            {...secondSrpLinkProps}
          />
          {isMobile && <div className="medium font-italic mt-0_75">{noNewForSaleText}</div>}
        </Fragment>
      )}
      <OtherYears
        heading={hasSecondSrpLink || hasUsedSecondSrpLink ? 'See other years' : undefined}
        headingClassName="mb-0_5 mt-2"
        vehicle={vehicle}
        renderEmptyHidden
        isMobile={isMobile}
        hasSpeculationRule
      />
    </Fragment>
  );

  const isAds9973Render = isAds9973Enabled && !isMobile && !isPreprod;

  return (
    <Container className={classnames('new-core-intro', className)}>
      <div className="d-flex flex-wrap align-items-start mb-md-0_5">
        <h1 className="intro-title heading-2 text-white mb-0_5 mr-md-1_5">{vehicleName}</h1>
        <LookingForModel model={lookingForModel} />
      </div>
      {(!isConnect || !isMobile) && (
        <Row
          className={classnames('intro-header align-items-end justify-content-between mb-0_5 mb-md-1_5', {
            'border-0': isPreprod && isMobile,
          })}
        >
          <Col xs={12} md={6} lg={7} className="px-0_5">
            <VehicleInfo
              vehicle={vehicle}
              firstContent={firstContent}
              isPreprod={isPreprod}
              isMobile={isMobile}
              className="mb-0_75 my-md-0 text-white"
            />
          </Col>
          {/* Empty Col can be rendered for critical CSS (CLS) */}
          <Col xs={12} md={6} lg={5} className="px-0_5">
            {!isPreprod && (
              <AnchorNavSticky
                navList={menuItems}
                title={null}
                stickyTitle={vehicleName}
                hasSeparator={false}
                containerClassName={null}
                titleClassName="heading-4 py-0_75 hidden-sm-down"
                className="intro-navigation"
                stickyClassName="justify-content-between pt-1 pt-md-0"
                listClassName={classnames('d-flex align-items-end mx-0 size-16', {
                  'justify-content-between': !isConnect,
                  'justify-content-md-end': isConnect,
                })}
                stickyListClassName={classnames('d-flex align-items-end mx-0 medium', {
                  'justify-content-between': !isConnect,
                  'justify-content-md-end': isConnect,
                })}
                anchorNavContentClassName="w-100"
                stickyAnchorNavContentClassName="col-12 col-md-6 col-lg-5 align-self-end px-0 pl-md-0_5"
                navWrapperClassName={null}
                itemClassName="px-0_75 px-xl-1"
                stickyItemClassName="px-0_75 px-xl-1"
                linkClassName="pt-md-0 text-white px-md-0_25 py-0_5"
                stickyLinkClassName="pt-md-0 py-0_5"
                linkActiveClassName="link-active font-weight-bold"
                stickyLinkActiveClassName="link-active text-primary-darker"
                creativeId="edm-entry-sub-nav"
                isMobile={isMobile}
                linkTrackingId="view_content_non_docked"
                stickyLinkTrackingId="view_content_docked"
              />
            )}
          </Col>
        </Row>
      )}

      <div className="intro-content">
        <ScrollElement className="intro-gallery overview-container" id="subnav-overview">
          <PhotoGallery
            params={params}
            isMobile={isMobile}
            vehicle={vehicle}
            editorialReview={editorialReview}
            isPreprod={isPreprod}
            photoSize={photoSize}
            isConnect={isConnect}
          />

          {!isMobile && !isPreprod && (
            <Experiment name="ads-9973" showDefault>
              <Recipe name="ctrl" isDefault />
              <Recipe name="chal1" />
              <Recipe name="chal2" />
            </Experiment>
          )}

          {isAds9973Render ? (
            <div className="bg-cool-gray-90 rounded-12 px-1 py-1 mt-1_5 mt-md-1_5">
              {hasCoreHighlights && (
                <CoreHighlights
                  className="pb-md-0_5"
                  params={params}
                  submodelSlug={submodelSlug}
                  vehicle={vehicle}
                  editorialReview={editorialReview}
                  isMobile={isMobile}
                  isCore5862Enabled={isCore5862Enabled}
                  isCore6311Test
                />
              )}
              {!hasCoreHighlights && highlightsListFallback}
              <Experiment name="ads-9973" showDefault>
                <Recipe name="ctrl" isDefault />
                <Recipe name="chal1">
                  <IncentivesAd
                    wrapperClass={classnames('mb-1', {
                      'mt-0_5': hasCoreHighlights,
                      'mt-1_5 mt-md-1_5': !hasCoreHighlights,
                    })}
                    cardWrapper="rounded-12 border-0"
                    horizontalLayout
                    showOnlySiteServed
                  />
                </Recipe>
                <Recipe name="chal2">
                  <IncentivesAd
                    wrapperClass={classnames('mb-1', {
                      'mt-0_5': hasCoreHighlights,
                      'mt-1_5 mt-md-1_5': !hasCoreHighlights,
                    })}
                    cardWrapper="rounded-12 border-0 box-shadow "
                    horizontalLayout
                    showOnlySiteServed
                  />
                </Recipe>
              </Experiment>
              {introOtherYearsPills}
            </div>
          ) : (
            <Fragment>
              {!isMobile && coreHighlights}
              {!isMobile && !isPreprod && !hasCoreHighlights && (
                <div className="bg-cool-gray-90 rounded-12 px-1 py-1 mt-1_5">{highlightsListFallback}</div>
              )}
            </Fragment>
          )}
        </ScrollElement>

        {isMobile && coreHighlights}

        <ScrollElement id="subnav-trim-summary-desktop" className="intro-trims d-flex flex-column">
          <StickyToBottom enabled={!isMobile} offsetSelector=".intro-header" additionalOffset={16}>
            {({ onHeightUpdate }) => (
              <Fragment>
                {isPreprod ? (
                  <div className="trim-summary-section rounded-12 px-0_5 pb-1 pt-1 mt-1 mt-md-1_5 mt-lg-0 bg-cool-gray-90">
                    <ScrollElement
                      id="subnav-trim-summary-mobile"
                      tag={Card}
                      className="intro-trims-content px-1 px-lg-0_5 px-xl-1 py-1 m-lg-0 border-0 rounded-8"
                      data-tracking-parent="edm-entry-model-summary"
                    >
                      {((!(hasHighlights && hasPreprodCta) && !isMobile) || (isMobile && !hasCoreHighlights)) &&
                        highlightsListFallback}
                      <PreprodPricingButton
                        srpLink={srpLink}
                        vehicle={vehicle}
                        isMobile={isMobile}
                        copyClassName="mb-1 text-gray-darker"
                        btnClassName="w-100 py-0_75 px-1"
                      />
                    </ScrollElement>
                  </div>
                ) : (
                  <div className="trim-summary-section modular rounded-12 bg-cool-gray-90 pb-1 pt-1 px-1 mt-1 mt-md-1_5 mt-lg-0">
                    {isMobile && !hasCoreHighlights && highlightsListFallback}
                    <h2
                      className={classnames('heading-3', {
                        'mb-1 mb-md-1_5': isCore5862Enabled,
                        'mb-1_5': !isCore5862Enabled,
                      })}
                    >
                      Pricing
                    </h2>
                    <ScrollElement
                      id="subnav-trim-summary-mobile"
                      tag={Card}
                      className="intro-trims-content px-1 px-lg-0_5 px-xl-1 py-1 m-lg-0 border-0 rounded-8"
                      data-tracking-parent="edm-entry-model-summary"
                    >
                      <YearSubmodelControls params={params} vehicle={vehicle} isCore6334Enabled={isCore6334Enabled} />
                      <TrimsCta
                        params={vehicleParams}
                        vehicle={vehicle}
                        visitorLocation={visitorLocation}
                        showLeadForm={showLeadForm}
                        isElectric={isElectric}
                        isDealerlessOem={params.isDealerlessOem}
                        isMobile={isMobile}
                        isCore6268Test
                        isCore6334Enabled={isCore6334Enabled}
                      />
                      {isMobile && (
                        <Fragment>
                          <hr className="w-100 mt-2 mb-0_5" />
                          <IncentivesAd mobile showOnlySiteServed noBorder />
                        </Fragment>
                      )}
                    </ScrollElement>
                  </div>
                )}
                {isMobile && isConnect && (
                  <EditorialHighlightsLists
                    editorialReview={editorialReview}
                    firstContent={firstContent}
                    params={params}
                    className="mt-1_5 mb-2"
                    sectionsToRender={[HIGHLIGHTS_NAME.PROS, HIGHLIGHTS_NAME.CONS, HIGHLIGHTS_NAME.WHAT_TO_EXPECT]}
                    isPreprod={isPreprod}
                  />
                )}
                {!isMobile && (
                  <BuildPriceButtonAtAGlanceAd
                    mobile={false}
                    position={'1'}
                    wrapperClass="mt-1"
                    slotRenderEndListener={onHeightUpdate}
                  />
                )}
              </Fragment>
            )}
          </StickyToBottom>
          {!isMobile && isUnsoldBuildAndPriceAd && (
            <MrectNative
              position={'0'}
              wiredBreakpoints={{ lg: true, xl: true }}
              useMinHeight
              wrapperClass="mt-1_5 hidden-md-down"
            />
          )}
        </ScrollElement>

        {!isAds9973Render && (
          <div className="intro-other-years-pills mt-2 mt-md-1 mt-lg-0 mb-2 mb-lg-0">
            {!isMobile && !isPreprod && incentiveAdDesktop}
            <div
              className={classnames({
                'with-srp-link-container bg-cool-gray-90 rounded-12 mx-0 p-1': hasSecondSrpLink || hasUsedSecondSrpLink,
              })}
            >
              {introOtherYearsPills}
            </div>
            {!isMobile && isPreprod && incentiveAdDesktop}
          </div>
        )}

        {!!(editorialReview || shortVideos || isPreprod) && (
          <ScrollElement id="subnav-reviews" className="intro-editorial">
            {!!shortVideos && (
              <VideoReviews
                className="mb-3_5"
                shortVideos={shortVideos}
                vehicleName={`${makeName} ${modelName}`}
                creativeId="video-carousel"
                isMobile={isMobile}
              />
            )}
            {!!editorialReview && (
              <ScorecardEditorialReviewBody
                params={params}
                vehicle={vehicle}
                hasRouteYearParam={!!urlYear}
                isPreprod={isPreprod}
                showHighlights
                hasPreprodCta={hasPreprodCta}
                embeddedModules={{ pricingModule, rangeAndCostModule, filmstripModule }}
                isExpandedScorecard
                hasCoreHighlights={hasCoreHighlights}
                hasTrimsLink={!isPreprod}
                isCore6112Enabled={isCore6112Enabled}
              />
            )}
            {isPreprod && (
              <PricingAd wrapperClass="mt-1_5 mb-1_5" mobile={isMobile} showHorizontalLayout showOnlySiteServed />
            )}
          </ScrollElement>
        )}

        {!isReviewLink && rangeAndCostModule}
        {!hasScorecardReview && (
          <div className="intro-other">
            {pricingModule}
            {filmstripModule}
          </div>
        )}
      </div>
    </Container>
  );
}

NewCoreIntroUI.propTypes = {
  params: CorePageParams.isRequired,
  vehicle: VehicleEntities.MakeModelSubmodelYear.isRequired,
  srpParams: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
    year: PropTypes.number,
    inventorytype: PropTypes.string,
    submodelid: PropTypes.string,
  }).isRequired,
  srpLink: PropTypes.string,
  className: PropTypes.string,
  visitorLocation: VisitorEntities.Location,
  isMobile: PropTypes.bool,
  editorialReview: EditorialReviewEntities.EditorialReview,
  consumerRatingsCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  prevYearConsumerRatingCount: ConsumerReviewsEntities.ConsumerReviewsRatingsCount,
  firstContent: FirstContentEntity,
  inventoryTotalNumber: PropTypes.number,
  hasLeadFormDealers: PropTypes.bool,
  hasPreprodDealers: PropTypes.bool,
  isPreprod: PropTypes.bool,
  isInternational: PropTypes.bool,
  isConnect: PropTypes.bool,
  isUnsoldBuildAndPriceAd: PropTypes.bool,
  isCore5862Enabled: PropTypes.bool,
  isCore6112Enabled: PropTypes.bool,
  isAds9973Enabled: PropTypes.bool,
  isCore6334Enabled: PropTypes.bool,
};

NewCoreIntroUI.defaultProps = {
  srpLink: null,
  className: 'pb-3_5',
  visitorLocation: {},
  isMobile: false,
  editorialReview: null,
  consumerRatingsCount: null,
  prevYearConsumerRatingCount: null,
  vehicle: null,
  firstContent: null,
  inventoryTotalNumber: null,
  hasLeadFormDealers: false,
  hasPreprodDealers: false,
  isPreprod: false,
  isInternational: false,
  isConnect: false,
  isUnsoldBuildAndPriceAd: false,
  isCore5862Enabled: false,
  isCore6112Enabled: false,
  isAds9973Enabled: false,
  isCore6334Enabled: false,
};

export const mapStateToProps = state => ({
  isAds9973Enabled: ExperimentUtil.getForcedOrAssignedRecipeName({
    state,
    campaignName: 'ads-9973',
    defaultVal: 'ctrl',
  }).includes('chal'),
});

export const NewCoreIntro = connectToModel(connect(mapStateToProps)(NewCoreIntroUI), {
  hasLeadFormDealers: bindToPath(
    ({ vehicle, isPreprod }) => !isPreprod && buildTrimSummariesPath(getParamsFromVehicle(vehicle)),
    InventoryCoreConfigurationsModel,
    trims => !!get(trims, '[0].hasLeadFormDealers')
  ),
  hasPreprodDealers: bindToPath(
    ({ vehicle, isPreprod, isCore5684Enabled }) =>
      isPreprod &&
      getUnifiedLeadFormDealerCountV2Path({
        makeSlug: vehicle.make.slug,
        modelSlug: vehicle.model.slug,
        strategy: CORE_5684_MAP[isCore5684Enabled],
      }),
    UnifiedLeadFormModel,
    dealerCount => !!dealerCount
  ),
  consumerRatingsCount: bindToPath(
    ({ params, isPreprod }) => !isPreprod && ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath(params),
    ConsumerReviewsModel
  ),
  prevYearConsumerRatingCount: bindToPath(
    ({ params, isPreprod }) =>
      !isPreprod && ConsumerReviewsPaths.buildConsumerReviewsRatingsCountPath({ ...params, year: params.year - 1 }),
    ConsumerReviewsModel
  ),
  isUnsoldBuildAndPriceAd: bindToPath('isUnsoldBuildAndPriceAd', NativeAdsCreativeConfigModel),
});
